import { createContext, ReactNode, useContext, useEffect, useState, useCallback, useRef } from 'react'
import { abilityCheck } from '@/utils/abilityCheck.ts'
import {
    AuthContextType,
    AuthStateType,
    UserDataType,
    AuthLoginRequestType,
    AuthLoginResponseType,
    LastUserDataType
} from '@/hooks/ClientAuth.type.ts'
import { abilityCheckFcn, UserAbilitiesActionType, UserAbilitiesPath } from '@/utils/abilityCheck.type.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiRequest } from '@/api/requests.ts'
import { envConfig } from '@/env/EnvConfig.ts'
import { toast } from 'react-toastify'
import { createLogoutToast } from '@/pages/auth/toasts/LogoutToast.tsx'
import { createLoginToast } from '@/pages/auth/toasts/LoginToast.tsx'
import { ApiResponseError } from '@/api/requests.type.ts'

// Constants
const API_URL_AUTH_LOGIN = '/auth/login'
const API_URL_AUTH_LOGOUT = '/auth/logout'
const LOCAL_STORAGE_KEY_AUTH_CONTEXT_USER = 'fe.react.user'
const LOCAL_STORAGE_KEY_AUTH_LAST_USER = 'fe.react.last-user'

// ** Defaults
const defaultAuthContext: AuthContextType = {
    user: null,
    lastUser: null,
    state: null,
    resetState: null,
    authenticate: null,
    ability: null
}

const defaultAuthState: AuthStateType = {
    context: 'unrestricted',
    transaction: null,
    pending: false
}

type AuthContextProviderProps = {
    children: ReactNode
}

const AuthContext = createContext(defaultAuthContext)
const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    // Constants

    const [user, setUser] = useState<UserDataType | null>(defaultAuthContext.user)
    const [lastUser, setLastUser] = useState<LastUserDataType | null>(null)
    const [state, setState] = useState<AuthStateType | null>(defaultAuthState)
    const init = useRef(false)
    const queryClient = useQueryClient()

    const loginMutation = useMutation<AuthLoginResponseType, ApiResponseError, AuthLoginRequestType>({
        mutationFn: async props =>
            apiRequest(envConfig.config.apiUrl + API_URL_AUTH_LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login: props.login, password: props.password })
            }),
        onSuccess: data => {
            window.localStorage.setItem(
                LOCAL_STORAGE_KEY_AUTH_LAST_USER,
                JSON.stringify({
                    login: data.me.login,
                    name: data.me.name
                })
            )
            setUser(data.me)
            queryClient.clear()
            window.localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_CONTEXT_USER, JSON.stringify(data.me))
            window.localStorage.setItem(
                LOCAL_STORAGE_KEY_AUTH_LAST_USER,
                JSON.stringify({
                    login: data.me.login,
                    name: data.me.name
                })
            )
            setState({
                context: 'restricted',
                pending: false,
                transaction: {
                    endpoint: API_URL_AUTH_LOGIN,
                    success: true,
                    error: null
                }
            })
            createLoginToast()
        },
        onError: async (error: ApiResponseError) => {
            setState({
                context: 'unrestricted',
                pending: false,
                transaction: {
                    endpoint: API_URL_AUTH_LOGIN,
                    success: null,
                    error: error
                }
            })
        }
    })

    const logoutMutation = useMutation({
        mutationFn: (id: string) =>
            apiRequest(envConfig.config.apiUrl + API_URL_AUTH_LOGOUT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: id }) //user?.login }) //user?.login })
            }),
        onSuccess: async () => {
            setState({
                context: 'logout',
                pending: false,
                transaction: {
                    endpoint: API_URL_AUTH_LOGOUT,
                    success: true,
                    error: null
                }
            })
            createLogoutToast(true)
        },
        onError: async (error: ApiResponseError) => {
            setState({
                context: 'logout',
                pending: false,
                transaction: {
                    endpoint: API_URL_AUTH_LOGOUT,
                    success: null,
                    error: error
                }
            })
            createLogoutToast(false)
        }
    })
    const resetState = () => {
        const oldState = state?.context ? state.context : 'unrestricted'
        setState({
            context: oldState == 'logout' ? 'unrestricted' : oldState,
            pending: false,
            transaction: null
        })
    }
    const authenticate = useCallback(
        (usr: AuthLoginRequestType | null = null) => {
            toast.dismiss()
            if (usr) {
                console.log('(login)', 'make validation_call to api,', usr.login)
                setState({
                    context: 'restricted',
                    pending: true,
                    transaction: {
                        endpoint: API_URL_AUTH_LOGIN,
                        success: true,
                        error: null
                    }
                })
                window.localStorage.setItem(
                    LOCAL_STORAGE_KEY_AUTH_LAST_USER,
                    JSON.stringify({
                        login: usr.login,
                        name: usr.login
                    })
                )
                loginMutation.mutate(usr)
            } else {
                const validationUser = user?.id
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_CONTEXT_USER)
                setUser(null)
                toast.dismiss()
                if (validationUser) {
                    setState({
                        context: 'logout',
                        pending: true,
                        transaction: {
                            endpoint: API_URL_AUTH_LOGOUT,
                            success: null,
                            error: null
                        }
                    })
                    console.log('(logout)', 'make validation_call to api: ', validationUser)
                    logoutMutation.mutate(validationUser)
                    window.history.replaceState(null, '', '?logout=1')
                } else {
                    createLogoutToast(false)
                }
            }
        },
        [user, loginMutation, logoutMutation]
    )

    useEffect(() => {
        const initAuth = function () {
            init.current = true
            const storedUser: string | null = window.localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_CONTEXT_USER)
            let sourceUser = 'null'
            if (storedUser) {
                setUser({ ...JSON.parse(storedUser) })
                sourceUser = 'local storage'
            } else {
                setUser(null)
            }
            let sourceLastUsedUser = 'null'
            const storedLastUsedUser: string | null = window.localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_LAST_USER)
            if (storedLastUsedUser) {
                setLastUser({ ...JSON.parse(storedLastUsedUser) })
                sourceLastUsedUser = 'local storage'
            } else {
                setLastUser(null)
            }
            console.log('(initAuth)', 'storedUser:', sourceUser, 'storedLastUsedUser:', sourceLastUsedUser)
        }
        if (!init.current) initAuth()
    }, [])

    const checkAbility: abilityCheckFcn = (
        abilityPath: UserAbilitiesPath = null,
        abilityAction: UserAbilitiesActionType = '*'
    ): boolean => {
        return abilityCheck(user?.abilities, abilityPath, abilityAction)
    }
    const values = {
        user,
        lastUser,
        state,
        resetState: resetState,
        authenticate: authenticate,
        ability: checkAbility
    }
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useClientAuth = () => useContext(AuthContext)

export { AuthContext, AuthContextProvider, useClientAuth }
