import 'react-datepicker/dist/react-datepicker.css'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import '@/theme/style.scss'

import { Fragment, StrictMode } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import '@/i18n/I18n.ts'
import { routes } from '@/routes.tsx'
import { ClientConfigProvider, bootstrapClientConfig } from '@/hooks/useClientConfig.tsx'
import { AuthContextProvider } from '@/hooks/useClientAuth.tsx'
import { ClientConfigType } from '@/hooks/ClientConfig.type.ts'
import { adjustConfig, switchMode, switchNav } from '@/hooks/clientConfigHelper.ts'
import { envConfig, envConfigUrl } from './env/EnvConfig'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import ToastEmitter from '@/layout/components/toasts/ToastEmitter.tsx'

// Configs
const baseStorageKey = 'fe.react'
const clientConfigStorageKey = baseStorageKey + '.client-config'
const enableStrictMode = true
// Bootstrapping
bootstrapClientConfig((config: ClientConfigType) => {
    console.log('(bootstrap)', 'strict mode=', enableStrictMode)

    config = adjustConfig(config)
    switchMode(config.mode)
    switchNav(config.nav)
    return config
}, clientConfigStorageKey)

fetch(envConfigUrl)
    .then(response => {
        if (!response?.ok) {
            throw new Error(response?.statusText)
        }
        return response.json()
    })
    .then(data => {
        envConfig.config = data
        setTimeout(() => {
            console.log('(startup)', 'version=', data.version)
            const queryClient = new QueryClient({
                defaultOptions: { queries: { retry: false } }
            })
            // Create react app
            let Wrapper = Fragment //fallback in case you dont want to wrap your components
            if (enableStrictMode) {
                Wrapper = StrictMode
            }

            createRoot(document.getElementById('root')!).render(
                <Wrapper>
                    <QueryClientProvider client={queryClient}>
                        <AuthContextProvider>
                            <ClientConfigProvider storageKey={clientConfigStorageKey}>
                                <RouterProvider
                                    router={createBrowserRouter(routes)}
                                    future={{ v7_startTransition: true }}
                                    fallbackElement={<span>Loading...</span>}
                                />
                                <ToastEmitter />
                            </ClientConfigProvider>
                        </AuthContextProvider>
                    </QueryClientProvider>
                </Wrapper>
            )
        }, 0)
    })
    .catch(error => {
        console.error('HANDLER', error)
    })
