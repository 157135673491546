import { ApiResponseError } from '@/api/requests.type.ts'
import i18next from '@/i18n/I18n'

export function isApiResponseError(e: any): e is ApiResponseError {
    return !!e && !!e.error && typeof e.error.code === 'string' && typeof e.error.timestamp === 'string'
}

export function createError(code: string, message?: string, subsys?: string): ApiResponseError {
    return {
        error: {
            code: code,
            message: message ? message : undefined,
            timestamp: new Date().toISOString(),
            subsys: 'fe:' + (subsys ? subsys : 'webclient')
        }
    }
}

export function handleError(e: any): ApiResponseError {
    if (e instanceof TypeError) {
        return createError('network_unavailable', i18next.t('shell.common.frontend_errors.network_unavailable'))
    } else if (e && isApiResponseError(e)) {
        return e
    } else {
        return createError('invalid_error_response', i18next.t('shell.common.frontend_errors.invalid_error_response'))
    }
}

export const apiFetch = async <T>(
    url: string,
    opts: RequestInit | undefined,
    successCb: (data: T) => void,
    errorCb: (err: ApiResponseError) => void,
    prepareCb?: () => void,
    finalizeCb?: () => void
): Promise<void> => {
    try {
        if (prepareCb) prepareCb()
        const response = await fetch(url, opts)
        const json = await response.json()

        if (!response.ok) {
            throw json
        }
        successCb(json)
    } catch (e) {
        const errorResponse = handleError(e)
        errorCb(errorResponse)
    }
    if (finalizeCb) finalizeCb()
}

export const apiRequest = async (url: string, opts: RequestInit | undefined) => {
    try {
        const response = await fetch(url, opts)
        const json = await response.json()
        if (!response.ok) throw json as ApiResponseError
        return json
    } catch (e) {
        throw handleError(e) as ApiResponseError
    }
}
