import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { ClientConfigType } from './ClientConfig.type.ts'

// Constants
const LOCAL_STORAGE_KEY_CLIENT_CONFIG = 'fe.react.client-config'

// Default Settings
const defaultClientConfig: ClientConfigType = {
    mode: 'auto',
    nav: 'closed'
}

// Context types
export type BootstrapClientConfigType = (config: ClientConfigType) => ClientConfigType
export type ClientConfigContextType = {
    config: ClientConfigType
    saveConfig: (updatedSettings: ClientConfigType) => void
}
type ClientConfigProviderProps = {
    children: ReactNode
    storageKey?: string
}

// Functions
const restoreConfig = (localStorageKey: string): ClientConfigType => {
    let config = defaultClientConfig
    try {
        const storedConfig: string | null = window.localStorage.getItem(localStorageKey)

        if (storedConfig) {
            config = { ...JSON.parse(storedConfig) }
        }
    } catch (err) {
        console.error(
            'ClientContext.restoreConfig: ' + 'window.localStorage.getItem("' + localStorageKey + '") xx' + err
        )
        window.localStorage.removeItem(localStorageKey)
    }
    return config
}
const storeConfig = (config: ClientConfigType, localStorageKey: string) => {
    try {
        const storeConfig = Object.assign({}, config)
        window.localStorage.setItem(localStorageKey, JSON.stringify(storeConfig))
    } catch (err) {
        console.error('ClientContext.storeConfig: ' + 'window.localStorage.setItem("' + localStorageKey + '") ' + err)
    }
}

// Create Client Config Context
const ClientConfigContext = createContext<ClientConfigContextType>({
    saveConfig: () => null,
    config: defaultClientConfig
})

const ClientConfigProvider = ({ children, storageKey }: ClientConfigProviderProps) => {
    storageKey = storageKey || LOCAL_STORAGE_KEY_CLIENT_CONFIG
    const [config, setConfig] = useState<ClientConfigType>(restoreConfig(storageKey))

    useEffect(() => {
        const restoredConfig = restoreConfig(storageKey)
        setConfig({ ...restoredConfig })
    }, [storageKey])

    return (
        <ClientConfigContext.Provider
            value={{
                config: config,
                saveConfig: (updatedSettings: ClientConfigType) => {
                    storeConfig(updatedSettings, storageKey)
                    setConfig(updatedSettings)
                }
            }}>
            {children}
        </ClientConfigContext.Provider>
    )
}

const bootstrapClientConfig = (bootstrap: BootstrapClientConfigType, storageKey?: string) => {
    storageKey = storageKey || LOCAL_STORAGE_KEY_CLIENT_CONFIG
    const restoredConfig = restoreConfig(storageKey)
    const modifiedConfig = bootstrap(restoredConfig)
    storeConfig(modifiedConfig, storageKey)
}

const useClientConfig = (): ClientConfigContextType => useContext(ClientConfigContext)
export {
    useClientConfig,
    ClientConfigProvider,
    bootstrapClientConfig,
    ClientConfigContext,
    defaultClientConfig,
    LOCAL_STORAGE_KEY_CLIENT_CONFIG
}
