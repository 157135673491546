import { RouteObject } from 'react-router-dom'

//import { Home } from './pages/Home'
//import { Page1 } from './pages/Page1'
//import { Page2 } from './pages/Page2withDelay'
//import { Page3 } from './pages/Page3withAnimation'

export const routes: RouteObject[] = [
    {
        children: [
            {
                index: true,
                lazy: () => import('@/pages/Home.tsx'),
                //element: <Home />,
                path: '/'
            },
            {
                lazy: () => import('@/pages/auth/ForgotPassword.tsx'),
                // element: <Page1 />,
                path: '/forgot-password'
            },
            {
                lazy: () => import('@/pages/auth/Register.tsx'),
                // element: <Page1 />,
                path: '/register'
            },
            {
                lazy: () => import('@/pages/legal/TermsOfService.tsx'),
                // element: <Page1 />,
                path: '/legal/tos'
            },
            {
                lazy: () => import('@/pages/legal/DataPrivacy.tsx'),
                // element: <Page1 />,
                path: '/legal/data-privacy'
            },
            {
                lazy: () => import('@/pages/legal/Imprint.tsx'),
                // element: <Page1 />,
                path: '/legal/imprint'
            },
            {
                lazy: () => import('@/pages/support/Contact.tsx'),
                // element: <Page1 />,
                path: '/contact'
            },
            {
                lazy: () => import('@/pages/Home1.tsx'),
                // element: <Page1 />,
                path: '/home1'
            },
            {
                lazy: () => import('@/pages/Home2.tsx'),
                // element: <Page1 />,
                path: '/home2'
            },
            {
                lazy: () => import('@/pages/Map.tsx'),
                // element: <Page1 />,
                path: '/map'
            },
            {
                lazy: () => import('@/pages/Page1.tsx'),
                // element: <Page1 />,
                path: '/page1'
            },
            {
                lazy: () => {
                    return new Promise(resolve => {
                        setTimeout(() => resolve(import('@/pages/Page2withDelay.tsx')), 2000)
                    })
                },
                //element: <Page2 />,
                path: '/page2'
            },
            {
                lazy: () => import('@/pages/Page3withAnimation.tsx'),
                //element: <Page3 />,
                path: '/page3'
            },
            {
                lazy: () => import('@/pages/PageTests.tsx'),
                //element: <PageTests />,
                path: '/tests'
            },
            {
                lazy: () => import('@/pages/PageI18n.tsx'),
                //element: <PageTests />,
                path: '/i18n'
            },
            {
                lazy: () => import('@/pages/CreateIssue.tsx'),
                //element: <PageTests />,
                path: '/create-issue'
            },
            {
                lazy: () => import('@/pages/Modals.tsx'),
                //element: <PageTests />,
                path: '/modals'
            },
            {
                lazy: () => import('@/pages/Toasts.tsx'),
                //element: <PageTests />,
                path: '/toasts'
            },
            {
                lazy: () => import('@/pages/Table.tsx'),
                //element: <PageTests />,
                path: '/table'
            },
            {
                lazy: () => import('@/pages/TableSimple.tsx'),
                //element: <PageTests />,
                path: '/table-simple'
            },
            {
                lazy: () => import('@/pages/Pure.tsx'),
                //element: <PageTests />,
                path: '/pure'
            },
            {
                //element: <Error404 />,
                lazy: () => import('@/pages/errors/Error404.tsx'),
                path: '*'
            }
        ]
    }
]
